<section class="w-full pt-6 pb-8 sm:pt-10 sm:pb-20"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">

  <div class="container flex flex-col text-brown-900">
    <div class="w-full sm:mx-auto sm:w-18/24">
      <h3 *ngIf="!contentModule.name_hidden"
          class="font-serif text-left font-400 text-28 md:text-40 mt-6 sm:mt-12 leading-130 line-clamp-2">{{ contentModule.name }}</h3>
      <div class="mt-2 w-full bg-gray-400 h-px"></div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-16">
        <div *ngFor="let slotable of contentModule.slotables" class="w-full mt-5 sm:mt-8">
          <app-slot-a90 [slotable]="slotable"></app-slot-a90>
        </div>
      </div>

    </div>
  </div>

</section>
