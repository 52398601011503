<section class="w-full py-4 md:pt-12 md:pb-14"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">

  <div class="w-full sm:container relative min-h-[608px] sm:min-h-[496px]">

    <div class="absolute inset-0 w-full flex items-end sm:items-center sm:justify-end">

      <img
        *ngIf="image"
        appImgFallback="/assets/img/default/oblun-default-image_1440x951.png"
        srcset="{{ image.i_840x560 }} 840w, {{ image.i_1440x951 }} 1440w"
        sizes="(max-width: 768px) 840px, (min-width: 768px) 1440px"
        src={{image.i_840x560}} alt={{contentModule.name}}
        class="w-full sm:w-18/24 self-end h-64 sm:h-full object-cover object-center">
      <img
        *ngIf="!image"
        src="/assets/img/default/oblun-default-image_1440x951.png"
        class="w-full sm:w-18/24 self-end h-64 sm:h-full object-cover object-center" [alt]="contentModule.name">


    </div>

    <div class="absolute inset-0 w-full h-full ui-module-a041-gradient">
      <div class="container sm:w-full h-full">
        <h2 class="sm:ml-12 mt-12 font-serif text-48 sm:text-68 text-white font-400 line-clamp-2">{{ contentModule.name }}</h2>
        <div class="sm:ml-12 mt-6 bg-gray-400 w-full sm:w-12/24 md:w-9/24 h-px"></div>
        <div class=" flex justify-between items-start w-full sm:w-12/24 md:w-9/24">
          <div *ngFor="let slotable of contentModule.slotables; let i = index"
               class="w-full">
            <app-slot-a041
              [url]="url"
              [isFirstSlot]="i === 0"
              [slotable]="slotable"></app-slot-a041>
          </div>

        </div>
        <div class="w-full sm:ml-12 sm:w-12/24 md:w-9/24 flex justify-start">
          <a *ngIf="url.url"
             [appFlLink]="url.url"
             class="hover:cursor-pointer hover:bg-white hover:bg-opacity-40 text-white mt-12 border w-full sm:min-w-46
                    sm:max-w-half flex justify-center items-center h-12 font-400 text-14 leading-170">
            <span *ngIf="!url.url_text" i18n="@@find_out_more">Saznaj više</span>
            <span *ngIf="url.url_text">{{ url.url_text }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>

</section>
