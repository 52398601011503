import {Component, Input, OnInit} from '@angular/core';
import {ContentModule} from '../../models/content-module.model';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-content-module-a012',
  templateUrl: './content-module-a012.component.html',
  styleUrls: ['./content-module-a012.component.scss']
})
export class ContentModuleA012Component implements OnInit {

  @Input() contentModule: ContentModule;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (this.contentModule.video_url) {
      this.contentModule.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.contentModule.video_url);
    }
  }
}
