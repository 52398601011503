import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { SeoItem } from './seo-item.model';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {currentLocale} from '../../routes';
import { LocaleService } from '../locale.service';
import { SeoRobotsService } from './seo-robots.service';
import { Meta } from '@angular/platform-browser';
import { SeoOgService } from './seo-og.service';
import { SeoTwitterService } from './seo-twitter.service';
import { SeoGeneralService } from './seo-general.service';
import { SeoRichDataService } from './seo-rich-data.service';
import {seoRulesDefault} from '../../../@modules/routing/seo-rules/seo-rules-default';
import {hasSeoItem} from './has-seo-item';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private seoGeneralService: SeoGeneralService,
    private seoOgService: SeoOgService,
    private seoTwitterService: SeoTwitterService,
    private seoRobotsService: SeoRobotsService,
    private seoRichDataService: SeoRichDataService,
    private metaTagService: Meta,
    private router: Router,
    private localeService: LocaleService,
    @Inject(LOCALE_ID) locale
  ) {
  }

  init(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.reset();
      }
    });
  }

  refreshForRoute(routeSnapshot: ActivatedRouteSnapshot): void {
    this.reset();

    if (!routeSnapshot.data) {
      this.refreshWithDefault();
    }

    const routeData = routeSnapshot.data;
    let seoItem;

    if (routeData && routeData.seoItem) {
      seoItem = routeData.seoItem;
    } else if (routeData.item && hasSeoItem(routeData.item)) {
      seoItem = routeData.item.getSeoItem();
    }

    if (seoItem) {
      this.refresh(seoItem);
    } else {
      this.refreshWithDefault();
    }
  }



  refreshForSeoItem(seoItem: SeoItem): void {
    this.refresh(seoItem);
  }

  refresh(seoItem: SeoItem): void {
    this.seoGeneralService.refresh(seoItem);
    this.seoOgService.refresh(seoItem);
    this.seoTwitterService.refresh(seoItem);
    this.seoRobotsService.refresh(seoItem);
  }

  refreshWithDefault(): void {
    const seoItem = new SeoItem({
      title: seoRulesDefault[currentLocale].title,
      description: seoRulesDefault[currentLocale].description,
      keywords: seoRulesDefault[currentLocale].keywords
    });
    this.refresh(seoItem);
  }

  reset(): void {
    this.seoGeneralService.reset();
    this.seoOgService.reset();
    this.seoTwitterService.reset();
    this.seoRobotsService.reset();
    this.seoRichDataService.reset();
  }
}
