<div class="w-full flex flex-col sm:flex-row">

  <div class="w-full md:w-16/24 flex flex-row space-x-2">
    <div *ngIf="moduleImage" class="w-half">
      <img [src]="moduleImage.i_1080x1920"
           appImgFallback="/assets/img/default/oblun-default-image_840x560.png"
           class="object-cover object-center aspect-2/3"
           alt={{slotable.title}}>
    </div>
    <div *ngIf="!moduleImage" class="w-half">
      <img src="/assets/img/default/oblun-default-image_840x560.png"
           class="object-cover object-center aspect-2/3"
           alt={{slotable.title}}>
    </div>
    <div *ngIf="slotable.image" class="w-half">
      <img [src]="slotable.image.i_1080x1920"
           appImgFallback="/assets/img/default/oblun-default-image_840x560.png"
           class="object-cover object-center aspect-2/3"
           alt={{slotable.title}}>
    </div>
    <div *ngIf="!slotable.image" class="w-half">
      <img src="/assets/img/default/oblun-default-image_840x560.png"
           class="object-cover object-center aspect-2/3"
           alt={{slotable.title}}>
    </div>
  </div>

  <div class="w-full md:w-8/24 text-brown-900">
    <div class="sm:ml-12">
      <h3 *ngIf="slotable.title"
          class="mt-4 md:mt-6 font-400 font-serif text-28 md:text-40 leading-120 line-clamp-2">{{ slotable.title }}</h3>
      <div *ngIf="slotable.description"
           class="mt-3 font-sans-serif text-14 md:text-16 leading-1700 font-300"
           [innerHTML]="slotable.description">
      </div>
      <a *ngIf="slotable.url"
         [appFlLink]="slotable.url"
         class="w-full sm:w-56 ui-btn mt-10 md:mt-6 py-2 px-18 sm:px-0 hover:cursor-pointer">
        <span *ngIf="!slotable.url_text" i18n="@@find_out_more">Saznaj više</span>
        <span *ngIf="slotable.url_text">{{ slotable.url_text }}</span>
      </a>
    </div>
  </div>

</div>
