import {Injectable} from '@angular/core';
import {Resource} from '../../../Library/resource/resource.model';
import {BaseAdapter} from '../../../Library/adapter/adapter.model';

export class Enquiry extends Resource<Request> {
  id: number;
  full_name?: string;
  email?: string;
  content?: string;
  enquiry_form_id?: number;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class EnquiryAdapter extends BaseAdapter<Enquiry> {
  constructor() {
    super(Enquiry);
  }

}
