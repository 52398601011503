<div class="w-full flex flex-col justify-center items-center text-brown-900">
    <h2
        class="font-serif font-400 text-28 sm:text-40 leading-120 mt-5 sm:mt-14 mb-3 sm:mb-6"
        i18n="@@contact_us"
    >
        Kontaktirajte nas!
    </h2>

    <form
        [formGroup]="contactForm"
        (ngSubmit)="onSubmit()"
        class="w-full flex flex-col justify-center items-center sm:px-4/24"
    >
        <div class="w-full flex flex-col sm:flex-row text-14">
            <div class="w-full flex flex-col">
                <label for="name" i18n="@@contact_name">Ime</label>
                <input
                    type="text"
                    id="name"
                    formControlName="name"
                    class="border border-brown-900 h-12 sm:h-10 focus:outline-none focus:ring-0 focus:border-brown-900 text-16 font-500 text-brown-900"
                    [ngClass]="{
                        'border-status-error-900 text-status-error-900':
                            name.errors?.['required'] && name.touched
                    }"
                />
                <p
                    *ngIf="name.errors?.['required'] && name.touched"
                    class="text-status-error-900 text-start text-12 font-500 mt-1 ml-1"
                    i18n="@@contact_required"
                >
                    Ovo polje je obavezno
                </p>
            </div>
            <div class="w-full flex flex-col sm:ml-4">
                <label for="last_name" i18n="@@contact_last_name"
                    >Prezime</label
                >
                <input
                    type="text"
                    id="last_name"
                    formControlName="last_name"
                    class="border border-brown-900 h-12 sm:h-10 focus:outline-none focus:ring-0 focus:border-brown-900 text-16 font-500 text-brown-900"
                    [ngClass]="{
                        'border-status-error-900 text-status-error-900':
                            last_name.errors?.['required'] && last_name.touched
                    }"
                />
                <p
                    *ngIf="last_name.errors?.['required'] && last_name.touched"
                    class="text-status-error-900 text-start text-12 font-500 mt-1 ml-1"
                    i18n="@@contact_required"
                >
                    Ovo polje je obavezno
                </p>
            </div>
        </div>
        <div class="w-full flex flex-col text-14 mt-2 sm:mt-3">
            <label for="email" i18n="@@contact_email_address"
                >E-mail adresa</label
            >
            <input
                type="text"
                id="email"
                formControlName="email"
                class="border border-brown-900 h-12 sm:h-10 text-16 font-500 focus:outline-none focus:ring-0 focus:border-brown-900 text-brown-900"
                [ngClass]="{
                    'border-status-error-900 text-status-error-900':
                        !email.valid && email.touched
                }"
            />
            <p
                *ngIf="email.errors?.['required'] && email.touched"
                class="text-status-error-900 text-start text-12 font-500 mt-1 ml-1"
                i18n="@@contact_required"
            >
                Ovo polje je obavezno
            </p>
            <p
                *ngIf="email.errors?.['email'] && email.touched"
                class="text-status-error-900 text-start text-12 font-500 mt-1 ml-1"
                i18n="@@contact_email_invalid"
            >
                Neispravna email adresa
            </p>
        </div>
        <div class="w-full flex flex-col text-14 mt-2 sm:mt-3">
            <label for="phone_number" i18n="@@contact_phone_number"
                >Broj telefona (Opcionalno)</label
            >
            <input
                type="text"
                id="phone_number"
                formControlName="phone_number"
                class="border border-brown-900 h-12 sm:h-10 text-16 font-500 focus:outline-none focus:ring-0 focus:border-brown-900 text-brown-900"
            />
        </div>
        <div class="w-full flex flex-col text-14 mt-2 sm:mt-3">
            <label for="message" i18n="@@contact_message">Poruka</label>
            <textarea
                id="message"
                formControlName="message"
                class="h-20 border border-brown-900 focus:outline-none focus:ring-0 focus:border-brown-900 text-16 font-500 text-brown-900"
                [ngClass]="{
                    'border-status-error-900 text-status-error-900':
                        !message.valid && message.touched
                }"
            >
            </textarea>
            <p
                *ngIf="!message.valid && message.touched"
                class="text-status-error-900 text-start text-12 font-500 mt-1 ml-1"
                i18n="@@contact_required"
            >
                Ovo polje je obavezno
            </p>
        </div>
        <div class="w-full flex justify-start mt-4 sm:mt-6">
            <input
                type="checkbox"
                id="condition"
                formControlName="condition"
                class="text-brown-900 border-2 border-brown-900 checked:bg-brown-900 checked:border-0 focus:ring-0 outline-none w-6 h-6"
            />
            <label
                class="text-gray-600 font-300 text-14 leading-170 ml-3"
                for="condition"
            >
                <span i18n>Upoznat sam i saglasan sa </span>
                <a i18n="@@terms_and_conditions" href="#" class="text-underline"
                    >Uslovima korištenja</a
                >
                <span i18n> i </span>
                <a i18n="@@privacy_policy" href="#" class="text-underline"
                    >Politikom privatnosti</a
                >
            </label>
        </div>

        <div class="w-full flex justify-center sm:justify-end items-center">
            <button
                type="submit"
                [disabled]="!contactForm.valid || loading"
                class="py-3 sm:py-2 w-full sm:w-56 text-center bg-brown-900 mt-12 sm:mt-8 text-white text-14 font-500 hover:bg-brown-600 border border-brown-900 leading-175 hover:cursor-pointer"
                [ngClass]="{
                    'bg-gray-light border-gray-light':
                        !contactForm.valid || loading
                }"
                i18n="@@hp_contact_send"
            >
                Pošalji
            </button>
        </div>

        <div
            *ngIf="displaySuccessMessage"
            class="mt-6 relative w-full flex justify-center items-center bg-status-success-100 text-status-success-900 font-500 text-16 border border-status-success-900"
        >
            <p class="py-4" i18n="@@contact_message_sent">
                Vaša poruka je uspješno poslata.
            </p>
            <span
                class="w-3 h-3 text-12 absolute right-2 top-2 flex justify-center items-center"
                (click)="displaySuccessMessage = false"
            >
                <fa-icon
                    [icon]="['fal', 'xmark']"
                    class="w-6 h-6 flex hover:cursor-pointer"
                ></fa-icon>
            </span>
        </div>

        <div
            *ngIf="displayErrorMessage"
            class="mt-6 relative w-full sm:w-10/24 flex justify-center items-center bg-status-error-100 text-status-error-900 font-500 text-16 border border-status-error-900"
        >
            <p class="py-4" i18n="@@contact_message_not_sent">
                Vaša poruka nije poslata.
            </p>
            <span
                class="w-3 h-3 text-12 absolute right-2 top-2 flex justify-center items-center"
                (click)="displayErrorMessage = false"
            >
                <fa-icon
                    [icon]="['fal', 'xmark']"
                    class="w-6 h-6 flex hover:cursor-pointer"
                ></fa-icon>
            </span>
        </div>
    </form>
</div>
