<section class="w-full"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">

  <div class="max-w-max mx-auto flex flex-col relative">
    <div class="absolute inset-0 h-54 sm:hidden ui-slot-a010-radial-gradient"></div>
    <img
      *ngIf="image"
      appImgFallback="/assets/img/default/oblun-default-image_1920x1080.png"
      srcset="{{ image.i_420x280 }} 420w, {{ image.i_1440x640 }} 1440w"
      sizes="(max-width: 768px) 420px, (min-width: 768px) 1440px"
      [src]="image.i_1440x640"
      class="w-full object-cover object-center h-54 sm:h-[610px]"
      alt={{contentModule.name}}>

    <img *ngIf="!image"
         src="/assets/img/default/oblun-default-image_1920x1080.png"
         class="w-full object-cover object-center h-54 sm:h-[610px]"
         alt={{contentModule.name}}>

    <div class="w-full sm:absolute sm:inset-0 ui-slot-a011-gradient py-8 sm:py-0">
      <div class="container h-full flex flex-row justify-center items-start flex flex-col">
        <div class="w-full sm:w-12/24 md:w-10/24 text-brown-900 sm:text-white">
          <h3 *ngIf="contentModule.name" class="font-400 text-36 mt-4 sm:mt-0 sm:text-68 font-serif leading-130 line-clamp-2">
            {{ contentModule.name }}
          </h3>
          <div class="mt-4 font-sans-serif text-16 leading-175" [innerHTML]="contentModule.description">
            {{ contentModule.description }}</div>

          <div class="mt-10 w-full bg-gray-400 h-px"></div>
          <div class="w-full flex items-center">

            <div *ngFor="let slotable of contentModule.slotables" class="mt-6 w-half flex justify-start">
              <app-slot-a011 [slotable]="slotable"></app-slot-a011>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</section>
