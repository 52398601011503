import { Component, Input, OnInit } from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';

@Component({
  templateUrl: './content-module-a090.component.html'
})
export class ContentModuleA090Component implements OnInit, ContentModuleBaseComponent {

  @Input() contentModule: ContentModule;
  @Input() even: boolean;

  ngOnInit(): void {
  }

}
