import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

declare var grecaptcha: any;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {

  recaptchaKey = environment.recaptchaSiteKey;
  recaptchaLoaded = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) public platformId
  ) {
  }

  init(): void {
    if (isPlatformBrowser(this.platformId)) {
      window['reCaptchaLoad'] = () => {
        grecaptcha.ready(() => {
          this.recaptchaLoaded.next(true);
        });
      };

      const url = 'https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=' + this.recaptchaKey;
      const tag = this.document.createElement('script');
      tag.type = 'text/javascript';
      tag.async = true;
      tag.defer = true;
      tag.src = url;
      this.document.body.appendChild(tag);
    }
  }

  verify(action?): Observable<boolean> {
    if (!this.recaptchaLoaded.value) {
      this.init();
    }

    return this.recaptchaLoaded.asObservable().pipe(
      filter((isLoaded) => isLoaded === true),
      take(1),
      switchMap((isLoaded) => {
        return from(grecaptcha.execute(this.recaptchaKey, {action}));
      })
    ).pipe(
      take(1),
      switchMap((token) => {
        if (!token) {
          return of(false);
        }

        return this.apiVerify(token);
      })
    );
  }

  apiVerify(token): Observable<boolean> {
    return this.httpClient.post(environment.apiUrl + '/recaptcha/verify', {token}).pipe(
      take(1),
      map((responseData: any) => {
        const result = responseData.data;
        return result.result && result.result === 'verified' && token === result.token;
      })
    );
  }
}
