<section class="w-full container">
  <div class="container">
    <div *ngIf="contentModule.sanitizedVideoUrl" class="w-full aspect-video mt-4 sm:mt-10">
      <div
        style="padding-top: 56.25%;" class="relative object-cover">
        <iframe
          [src]="contentModule.sanitizedVideoUrl"
          class="absolute top-0 left-0 w-full h-full"
          allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</section>
