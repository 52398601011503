import { Component, Input, OnInit, ViewChild, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { ContentModuleDirective } from '../directives/content-module.directive';
import { ContentModule } from '../models/content-module.model';
import { ContentModuleBaseComponent } from './content-module-base.component';
import { ContentModuleA020Component } from './content-module-a020/content-module-a020.component';
import { ContentModuleA030Component } from './content-module-a030/content-module-a030.component';
import { ContentModuleA040Component } from './content-module-a040/content-module-a040.component';
import { ContentModuleA050Component } from './content-module-a050/content-module-a050.component';
import { ContentModuleA060Component } from './content-module-a060/content-module-a060.component';
import { ContentModuleA070Component } from './content-module-a070/content-module-a070.component';
import { ContentModuleA080Component } from './content-module-a080/content-module-a080.component';
import { ContentModuleA090Component } from './content-module-a090/content-module-a090.component';
import { ContentModuleA031Component } from './content-module-a031/content-module-a031.component';
import { ContentModuleA041Component } from './content-module-a041/content-module-a041.component';
import { ContentModuleA071Component } from './content-module-a071/content-module-a071.component';
import { ContentModuleA010Component } from './content-module-a010/content-module-a010.component';
import { ContentModuleA011Component } from './content-module-a011/content-module-a011.component';
import { ContentModuleA012Component } from './content-module-a012/content-module-a012.component';
import { ContentModuleD100Component } from './content-module-d100/content-module-d100.component';
import { ContentModuleD200Component } from './content-module-d200/content-module-d200.component';
import { ContentModuleD300Component } from './content-module-d300/content-module-d300.component';

@Component({
  selector: 'app-content-module',
  template: `
    <ng-template appModuleLayoutHost></ng-template>`,
})
export class ContentModuleComponent implements OnInit, OnDestroy {
  @Input() contentModule: ContentModule;
  @Input() even: boolean;
  @ViewChild(ContentModuleDirective, {static: true}) appModuleLayoutHost: ContentModuleDirective;
  interval: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.createComponent();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  createComponent(): void {
    let component;


    switch (this.contentModule.layout) {
      case 'moduleA010':
        component = ContentModuleA010Component;
        break;
      case 'moduleA011':
        component = ContentModuleA011Component;
        break;
        case 'moduleA012':
          component = ContentModuleA012Component;
          break;
      case 'moduleA020':
        component = ContentModuleA020Component;
        break;
      case 'moduleA030':
        component = ContentModuleA030Component;
        break;
      case 'moduleA031':
        component = ContentModuleA031Component;
        break;
      case 'moduleA040':
        component = ContentModuleA040Component;
        break;
      case 'moduleA041':
        component = ContentModuleA041Component;
        break;
      case 'moduleA050':
        component = ContentModuleA050Component;
        break;
      case 'moduleA060':
        component = ContentModuleA060Component;
        break;
      case 'moduleA070':
        component = ContentModuleA070Component;
        break;
      case 'moduleA071':
        component = ContentModuleA071Component;
        break;
      case 'moduleA080':
        component = ContentModuleA080Component;
        break;
      case 'moduleA090':
        component = ContentModuleA090Component;
        break;
      case 'moduleD100':
        component = ContentModuleD100Component;
        break;
      case 'moduleD200':
        component = ContentModuleD200Component;
        break;
      case 'moduleD300':
        component = ContentModuleD300Component;
    }


    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const containerRef = this.appModuleLayoutHost.viewContainerRef;

    containerRef.clear();

    const componentRef = containerRef.createComponent<ContentModuleBaseComponent>(componentFactory);

    componentRef.instance.contentModule = this.contentModule;
    componentRef.instance.even = this.even;
  }
}
