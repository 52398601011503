import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { Media } from '../../../../Library/media/models/media.model';

@Component({
  selector: 'app-content-module-a030',
  templateUrl: './content-module-a030.component.html',
})
export class ContentModuleA030Component implements OnInit {

  @Input() contentModule: ContentModule;
  image: Media;
  @Input() even: boolean;

  constructor() {
  }

  ngOnInit(): void {
    if (this.contentModule.image) {
      this.image = this.contentModule.image;
    } else if (this.contentModule.slotables[0].image) {
      this.image = this.contentModule.slotables[0].image;
    }
  }

}
