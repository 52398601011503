<section class="w-full"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">
  <div class="container py-4 sm:pt-16 sm:pb-20 flex flex-col sm:flex-row justify-center sm:items-start">
    <div class="w-full h-56 sm:w-12/24 md:w-15/24 md:h-[480px] relative overflow-hidden aspect-3/2">
      <div class="absolute inset-0 w-full">
        <img
          *ngIf="image"
          appImgFallback="/assets/img/default/oblun-default-image_840x560.png"
          srcset="{{ image.i_420x280 }} 420w, {{ image.i_840x560 }} 840w"
          sizes="(max-width: 768px) 420px, (min-width: 768px) 840px"
          src={{image.i_840x560}} alt={{contentModule.name}}
          class="object-cover object-center">

        <img
          *ngIf="!image"
          src="/assets/img/default/oblun-default-image_840x560.png"
          class="object-cover object-center">

      </div>
    </div>
    <div *ngFor="let slotable of contentModule.slotables"
         class="w-full flex md:min-h-[480px] justify-center sm:justify-start sm:w-11/24 items-start md:w-8/24 sm:pl-1/24 h-full">
      <app-slot-a30 class="w-full" [slotable]="slotable"></app-slot-a30>
    </div>
  </div>
</section>
