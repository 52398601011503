<div class="w-full flex flex-col justify-center items-center sm:items-start">

  <img *ngIf="slotable.image"
       appImgFallback="/assets/img/default/oblun-default-image_1440x951.png"
       srcset="{{ slotable.image?.i_840x560 }} 840w, {{ slotable.image?.i_1440x951 }} 1440w"
       sizes="(max-width: 768px) 840px, (min-width: 768px) 1440px"
       [src]="slotable.image.i_840x560" [alt]=slotable.title
       class="w-full h-full object-cover object-center">
  <img
    *ngIf="!slotable.image"
    src="/assets/img/default/oblun-default-image_1440x951.png" [alt]=slotable.title>

  <div class="w-full flex justify-start">
    <h3 *ngIf="slotable.title"
        class="text-40 sm:text-48 leading-130 font-serif text-left font-400 text-brown-900 mt-4 sm:mt-6 mb-3 line-clamp-2">
      {{ slotable.title }}
    </h3>
  </div>

  <div *ngIf="slotable.description"
       class="w-full sm:w-22/24 text-14 sm:text-16 leading-175 text-left"
       [innerHTML]="slotable?.description"></div>

  <a *ngIf="slotable.url"
     class="hover:cursor-pointer ui-btn h-10 flex justify-center items-center w-full md:w-auto md:min-w-56 mt-10"
     [appFlLink]="slotable.url">
    <span *ngIf="!slotable.url_text" i18n="@@find_out_more">Saznaj više</span>
    <span *ngIf="slotable.url_text">{{ slotable.url_text }}</span>
  </a>
</div>
