<section class="w-full py-4 sm:py-16"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}"
>
  <div class="container grid grid-cols-1 sm:grid-cols-2 gap-4">
    <div *ngFor="let slotable of contentModule.slotables" class="w-full">
      <app-slot-a60 [slotable]="slotable"></app-slot-a60>
    </div>
  </div>
</section>
