import {Component, Input, OnInit} from '@angular/core';
import { Media } from 'src/app/Library/media/models/media.model';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a80',
  templateUrl: './slot-a80.component.html',
  styleUrls: ['./slot-a80.component.scss'],
})
export class SlotA80Component implements OnInit {

  @Input() slotable: Slot;
  @Input() type: string;
  @Input() image: Media;
  apiPath: string;
  constructor() { }

  ngOnInit(): void {
  }

}
