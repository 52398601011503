<section class="w-full py-8 sm:py-16"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">
  <div class="container flex">
    <div *ngFor="let slotable of contentModule.slotables" class="w-full">
      <app-slot-a70 [slotable]="slotable" [moduleImage]="moduleImage"></app-slot-a70>
    </div>
  </div>

</section>
