<section class="w-full"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">
  <div class="w-full flex flex-col items-start">
    <div *ngFor="let slotable of contentModule.slotables" class="w-full">
      <app-slot-a10 [slotable]="slotable" [image]="image"></app-slot-a10>
    </div>
  </div>
</section>
