<div *ngIf="item" [style]="style" class="pt-16 sm:pt-0 ui-page">
  <div *ngFor="let group of groupedModules; let even = even, let i = index"
       class="w-full"
       [ngClass]="{ 'bg-gray-300' : group.isHighlighted }">
    <div
      [ngClass]="{ 'container flex flex-col sm:flex-row flex-wrap': !group.hasFixedWidth }">
      <div *ngFor="let module of group.modules" class="flex {{ module.column_width }}"
           [ngClass]="{ 'hidden sm:flex' : module.mobile_hidden, 'sm:hidden' : module.desktop_hidden }">
        <app-content-module class="w-full" [contentModule]="module" [even]="even"></app-content-module>
      </div>
    </div>
  </div>
</div>
