export const routes: any = {
  me: {
    homepage: 'naslovna',
    contact: 'kontakt',
    page: '',
    accommodation: 'smjestaj',
    accomodation_offer: 'ponuda-smjestaja',
    details: 'detalji',
    single_offer: 'ponuda',
    gallery: 'galerija',
    about: 'o-nama',
    terms: 'uslovi-koriscenja',
    privacy: 'politika-privatnosti'
  },
  en: {
    homepage: 'homepage',
    contact: 'contact',
    page: '',
    accommodation: 'accommodation',
    accomodation_offer: 'accommodation-offer',
    details: 'details',
    single_offer: 'single-offer',
    gallery : 'gallery',
    about: 'about-us',
    terms: 'terms-and-conditions',
    privacy: 'privacy-policy'
  },
};

export enum RouteName {
  HOMEPAGE = 'homepage',
  CUSTOM_PAGE = 'customPage',
  NOT_FOUND = 'notFound',
  CONTACT = 'contact',
  ACCOMODATION = 'accommodation',
  DETAILS = 'details'
}

export let localeMapping: {} = {
  'sr-Latn-ME': 'me',
  'en-US': 'en',
  en: 'en',
};

export let currentLocale: string = $localize`:@@locale_code_sr_or_en|CurrentLocaleShortCode|CurrentLocaleShortCode:me`;
