import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { Media } from '../../../../Library/media/models/media.model';

@Component({
  selector: 'app-content-module-a071',
  templateUrl: './content-module-a071.component.html',
  styleUrls: ['./content-module-a071.component.scss']
})
export class ContentModuleA071Component implements OnInit {

  @Input() contentModule: ContentModule;
  @Input() even: boolean;
  moduleImage: Media;

  constructor() {
  }

  ngOnInit(): void {
    if (this.contentModule.image) {
      this.moduleImage = this.contentModule.image;
    }
  }

}
