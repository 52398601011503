import { Component, Input, OnInit } from '@angular/core';
import Helpers from '../../../../Library/helpers';
import { Slot } from '../../models/slot.model';
import { Media } from '../../../../Library/media/models/media.model';

@Component({
  selector: 'app-slot-a10',
  templateUrl: './slot-a10.component.html',
  styleUrls: ['slot-a10.component.scss']
})
export class SlotA10Component implements OnInit {

  @Input() slotable: Slot;
  @Input() image: Media;

  constructor() {
  }

  ngOnInit(): void {
  }
}
