import { ContentModuleA012Component } from './components/content-module-a012/content-module-a012.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../@shared/shared.module';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons';

import { EnquiriesService } from '../../Library/contact/services/enquiries.service';
import { ReactiveFormsModule } from '@angular/forms';

import { ContentModuleDirective } from './directives/content-module.directive';
import { ContentModuleComponent } from './components/content-module.component';
import { ContentModuleA010Component } from './components/content-module-a010/content-module-a010.component';
import { ContentModuleA020Component } from './components/content-module-a020/content-module-a020.component';
import { ContentModuleA030Component } from './components/content-module-a030/content-module-a030.component';
import { ContentModuleA031Component } from './components/content-module-a031/content-module-a031.component';
import { ContentModuleA040Component } from './components/content-module-a040/content-module-a040.component';
import { ContentModuleA041Component } from './components/content-module-a041/content-module-a041.component';
import { ContentModuleA050Component } from './components/content-module-a050/content-module-a050.component';
import { ContentModuleA060Component } from './components/content-module-a060/content-module-a060.component';
import { ContentModuleA070Component } from './components/content-module-a070/content-module-a070.component';
import { ContentModuleA071Component } from './components/content-module-a071/content-module-a071.component';
import { ContentModuleA080Component } from './components/content-module-a080/content-module-a080.component';
import { ContentModuleA090Component } from './components/content-module-a090/content-module-a090.component';
import { SlotA20Component } from './components/slot-a20/slot-a20.component';
import { SlotA30Component } from './components/slot-a30/slot-a30.component';
import { SlotA031Component } from './components/slot-a031/slot-a031.component';
import { SlotA40Component } from './components/slot-a40/slot-a40.component';
import { SlotA041Component } from './components/slot-a041/slot-a041.component';
import { SlotA50Component } from './components/slot-a50/slot-a50.component';
import { SlotA60Component } from './components/slot-a60/slot-a60.component';
import { SlotA70Component } from './components/slot-a70/slot-a70.component';
import { SlotA071Component } from './components/slot-a071/slot-a071.component';
import { SlotA80Component } from './components/slot-a80/slot-a80.component';
import { SlotA90Component } from './components/slot-a90/slot-a90.component';
import { RouterModule } from '@angular/router';
import { SlotA10Component } from './components/slot-a10/slot-a10.component';
import { ContentModuleA011Component } from './components/content-module-a011/content-module-a011.component';
import { SlotA011Component } from './components/slot-a011/slot-a011.component';
import { ContentModuleD100Component } from './components/content-module-d100/content-module-d100.component';
import { ContentModuleD200Component } from './components/content-module-d200/content-module-d200.component';
import { ContentModuleD300Component } from './components/content-module-d300/content-module-d300.component';
import { SlotD100Component } from './components/slot-d100/slot-d100.component';
import { SlotD200Component } from './components/slot-d200/slot-d200.component';
import { SlotD300Component } from './components/slot-d300/slot-d300.component';





@NgModule({
  declarations: [
    ContentModuleDirective,
    ContentModuleComponent,
    ContentModuleA010Component,
    ContentModuleA011Component,
    ContentModuleA020Component,
    ContentModuleA030Component,
    ContentModuleA040Component,
    ContentModuleA050Component,
    ContentModuleA060Component,
    ContentModuleA070Component,
    ContentModuleA080Component,
    ContentModuleA090Component,
    ContentModuleA031Component,
    ContentModuleA041Component,
    ContentModuleA071Component,
    SlotA10Component,
    SlotA011Component,
    SlotA20Component,
    SlotA30Component,
    SlotA40Component,
    SlotA50Component,
    SlotA60Component,
    SlotA70Component,
    SlotA80Component,
    SlotA90Component,
    SlotA071Component,
    SlotA031Component,
    SlotA041Component,
    ContentModuleD100Component,
    ContentModuleD200Component,
    ContentModuleD300Component,
    SlotD100Component,
    SlotD200Component,
    SlotD300Component,
    ContentModuleA012Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    ContentModuleComponent,
  ],
  providers: [
    EnquiriesService,
  ],
})
export class ContentModuleModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faLightbulbOn);
  }
}
