<div class="max-w-max mx-auto flex flex-col relative">
  <div class="absolute inset-0 h-54 sm:hidden ui-slot-a010-radial-gradient"></div>
  <img
    *ngIf="image"
    appImgFallback="/assets/img/default/oblun-default-image_1920x1080.png"
    srcset="{{ image.i_420x280 }} 420w, {{ image.i_1440x640 }} 1440w"
    sizes="(max-width: 768px) 420px, (min-width: 768px) 1440px"
    [src]="image.i_1440x640"
    class="w-full object-cover object-center h-54 sm:h-[610px]"
    alt={{slotable.title}}>

  <img *ngIf="!image"
       src="/assets/img/default/oblun-default-image_1920x1080.png"
       class="w-full object-cover object-center h-54 sm:h-[610px]"
       alt={{slotable.title}}>

  <div class="w-full sm:absolute sm:inset-0 ui-slot-a010-gradient b-8 sm:pb-0">
    <div class="container h-full flex flex-col justify-center items-start">
      <div class="w-full sm:w-12/24 md:w-10/24 text-brown-900 sm:text-white flex flex-col">
        <h3 *ngIf="slotable.title"
            class="font-400 text-36 mt-4 sm:mt-0 sm:text-68 font-serif leading-115 line-clamp-2">{{ slotable.title }}</h3>
        <div class="my-4 font-sans-serif text-16 leading-175" [innerHTML]="slotable.description"></div>
        <a *ngIf="slotable.url"
           [appFlLink]="slotable.url"
           class="hover:cursor-pointer border border-brown-900 sm:border-white sm:min-w-56 self-stretch sm:self-start px-4 py-2 font-400 text-14 leading-170
           hover:bg-white hover:bg-opacity-40 sm:hover:text-white flex items-center justify-center min-h-10">
          <span *ngIf="!slotable.url_text" i18n="@@find_out_more">Saznaj više</span>
          <span *ngIf="slotable.url_text">{{ slotable.url_text }}</span>
        </a>
      </div>
    </div>
  </div>

</div>
