<div class="w-full h-full">

  <div class="w-full sm:w-14/24">
    <h3 *ngIf="slotable.title"
        class="font-400 font-serif text-28 md:text-40 leading-130 line-clamp-2">{{ slotable.title }}</h3>
    <div *ngIf="slotable.description"
         class="mt-4 font-sans-serif text-14 md:text-16 leading-170 font-300" [innerHTML]="slotable.description"></div>
  </div>

  <img
    *ngIf="image"
    appImgFallback="/assets/img/default/oblun-default-image_1440x951.png"
    srcset="{{ image.i_420x280 }} 420w, {{ image.i_1440x951 }} 1440w"
    sizes="(max-width: 768px) 420px, (min-width: 768px) 1440px"
    [src]="image.i_1440x951"
    class="object-cover object-center mt-10 w-full h-46 sm:h-[496px]"
    [alt]="slotable.title">

  <img
    *ngIf="!image"
    src="/assets/img/default/oblun-default-image_1440x951.png"
    class="object-cover object-center mt-10 w-full h-46 sm:h-[496px]"
    [alt]="slotable.title">

</div>
