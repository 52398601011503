import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResourceService } from '../../../Library/resource/resource.service';
import { Enquiry, EnquiryAdapter } from '../models/enquiry.model';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService extends ResourceService<Enquiry> {

  constructor(httpClient: HttpClient, adapter: EnquiryAdapter) {
    super('/enquiries', httpClient, adapter);
  }

}
