import {Component, Input, OnInit} from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a40',
  templateUrl: './slot-a40.component.html',
  styleUrls: ['./slot-a40.component.scss']
})
export class SlotA40Component implements OnInit {

  @Input() slotable: Slot;
  @Input() index: number;

  constructor() { }

  ngOnInit(): void {
  }

}
