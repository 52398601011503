<div class="w-full flex flex-col items-start text-white sm:ml-12">

  <div class="w-full flex text-white justify-between mt-4 sm:mt-6 font-sans-serif">
    <div class="flex flex-col justify-start">
      <h3 *ngIf="slotable.title"
          class="font-300 text-14 leading-170 text-brown-800 sm:text-gray-200 line-clamp-2">{{ slotable.title }}</h3>
      <div class="flex flex-col items-start text-left font-500 text-16 sm:text-18 leading-200"
           [innerHTML]="slotable.description">
      </div>
    </div>
  </div>
</div>

