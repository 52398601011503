<section class="w-full py-4 md:pt-12 md:pb-14"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">

  <div class="w-full sm:container relative min-h-[608px] sm:min-h-[496px]">

    <div class="absolute inset-0 w-full flex items-end sm:items-center sm:justify-end">

      <img
        *ngIf="image"
        appImgFallback="/assets/img/default/ph_img.jpg"
        srcset="{{ image.i_840x560 }} 840w, {{ image.i_1440x951 }} 1440w"
        sizes="(max-width: 768px) 840px, (min-width: 768px) 1440px"
        [src]="image.i_840x560" [alt]="contentModule.name"
        class="w-full sm:w-18/24 self-end h-64 sm:h-full object-cover object-center">

      <img
        *ngIf="!image"
        [alt]="contentModule.name"
        src="/assets/img/default/oblun-default-image_1440x951.png"
        class="w-full sm:w-18/24 self-end h-64 sm:h-full object-cover object-center">

    </div>




    <div class="absolute inset-0 w-full h-full z-10 ui-module-a040-gradient">
      <div class="container sm:w-full h-full">
        <div *ngFor="let slotable of contentModule.slotables"
             class="w-full h-full sm:w-12/24 md:w-10/24">
          <app-slot-a40 [slotable]="slotable"></app-slot-a40>
        </div>
      </div>
    </div>
  </div>

</section>
