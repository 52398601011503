import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { routes } from "../routes";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LocaleService {
  private currentRouteTranslationsSubject: BehaviorSubject<RouteTranslation> =
    new BehaviorSubject<RouteTranslation>({
      en: this.sanitizer.bypassSecurityTrustUrl(
        environment.websiteUrl + "/",
      ),
      me: this.sanitizer.bypassSecurityTrustUrl(environment.websiteUrl + '/me'),
    });
  public currentRouteTranslations$: Observable<RouteTranslation>;

  private static getObjectUrlPrefix(object: string, locale: string): string {
    switch (object) {
      case "article":
        if (locale === "en") {
          return "article/";
        } else {
          return "clanak/";
        }
      case "propertyType":
        return routes[locale].accommodation + "/";
    }

    return "";
  }

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
  ) {
    this.currentRouteTranslations$ =
      this.currentRouteTranslationsSubject.asObservable();
  }

  public getLocale(): string {
    switch (this.locale) {
      case "sr-Latn-ME":
      case "me":
        return "me";
      case "en-US":
      case "en":
        return "en";
      default:
        return this.locale;
    }
  }

  public getLocaleBaseUrl(): string {
    switch (this.locale) {
      case "sr-Latn-ME":
      case "me":
        return environment.websiteUrl + "/me/";
      case "en-US":
      case "en":
        return environment.websiteUrl + "/";
      default:
        return environment.websiteUrl + "/";
    }
  }

  public getRouteByElement(element: string, locale?: string): string {
    if (!locale) {
      locale = this.getLocale();
    }
    return routes[locale][element] || element;
  }

  public getRoute(locale: string, item?: any, objectType?: string): string {
    let route = "/";
    if (locale !== "en") {
      route += locale + "/";
    }
    if (item) {
      let translation = null;
      if (typeof item.getTranslation === "function") {
        translation = item.getTranslation(locale);
      }
      if (translation && objectType !== 'property') {
        route += LocaleService.getObjectUrlPrefix(objectType, locale);
        if (translation.path) {
          route += translation.path;
        } else if (translation.slug) {
          route += translation.slug;
        }
      } else if(objectType === 'property'){
        if(item.property_type.getTranslation(locale)?.slug && item.getTranslation(locale)?.slug) {
          route += `${routes[locale].accommodation}/${item.property_type.getTranslation(locale)?.slug}/${item.getTranslation(locale).slug}`;
        }
      }else {
        route = null;

      }
    }
    return route;
  }

  public getRouteByElements(elements: string | string[]): string {
    if (typeof elements === "string") {
      if (elements.startsWith("/")) {
        elements = elements.substring(1);
      }
      if (elements.endsWith("/")) {
        elements = elements.substring(0, elements.length - 1);
      }
      elements = elements.split("/");
    }
    let finalRoute = "";
    elements.forEach((part) => {
      finalRoute += "/" + this.getRouteByElement(part);
    });
    return finalRoute;
  }

  public setCurrentRouteTranslationsForItem(
    item?: any,
    objectType?: string,
  ): void {
    const sanitizedRoute: RouteTranslation = new RouteTranslation();

    let queryParams = "";
    for (const param in this.activatedRoute.snapshot.queryParams) {
      if (this.activatedRoute.snapshot.queryParams.hasOwnProperty(param)) {
        if (queryParams === "") {
          queryParams = "?";
        } else {
          queryParams += "&";
        }
        queryParams +=
          param + "=" + this.activatedRoute.snapshot.queryParams[param];
      }
    }
    const srRoute = this.getRoute("me", item, objectType);
    if (srRoute) {
      sanitizedRoute.me = this.sanitizer.bypassSecurityTrustUrl(
        environment.websiteUrl + srRoute + queryParams,
      );
    } else {
      sanitizedRoute.me = null;
    }

    const enRoute = this.getRoute("en", item, objectType);
    if (enRoute) {
      sanitizedRoute.en = this.sanitizer.bypassSecurityTrustUrl(
        environment.websiteUrl + enRoute + queryParams,
      );
    } else {
      sanitizedRoute.en = null;
    }
    this.currentRouteTranslationsSubject.next(sanitizedRoute);
  }

  public setCurrentRouteTranslationsForState(state: string): void {
    const sanitizedRoute: RouteTranslation = new RouteTranslation();
    sanitizedRoute.me = this.sanitizer.bypassSecurityTrustUrl(
      environment.websiteUrl + "/me/" + this.getRouteByElement(state, "me"),
    );
    sanitizedRoute.en = this.sanitizer.bypassSecurityTrustUrl(
      environment.websiteUrl + "/" + this.getRouteByElement(state, "en"),
    );
    this.currentRouteTranslationsSubject.next(sanitizedRoute);
  }
}

export class RouteTranslation {
  en: SafeUrl;
  me: SafeUrl;
}
