import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class MediaConfigurationService {

  private config = {
    mediaInit: {
      sizes: {
        article: [
          {name: 'i_1080x1920', width: 1080, height: 1920},
          {name: 'i_1440x951', width: 1440, height: 951},
          {name: 'i_1440x640', width: 1440, height: 640},
          {name: 'i_1160x772', width: 1160, height: 772},
          {name: 'i_648x432', width: 648, height: 432},
          {name: 'i_840x560', width: 840, height: 560},
          {name: 'i_420x280', width: 420, height: 280},
          {name: 'thumbnail', width: 420, height: 280},
          {name: 'default', width: 1440, height: 951},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        module: [
          {name: 'i_1080x1920', width: 1080, height: 1920},
          {name: 'i_1440x951', width: 1440, height: 951},
          {name: 'i_1440x640', width: 1440, height: 640},
          {name: 'i_1160x772', width: 1160, height: 772},
          {name: 'i_648x432', width: 648, height: 432},
          {name: 'i_840x560', width: 840, height: 560},
          {name: 'i_420x280', width: 420, height: 280},
          {name: 'thumbnail', width: 420, height: 280},
          {name: 'default', width: 1440, height: 951},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        accommodation: [
          {name: 'i_1080x1920', width: 1080, height: 1920},
          {name: 'i_1440x951', width: 1440, height: 951},
          {name: 'i_1440x640', width: 1440, height: 640},
          {name: 'i_1160x772', width: 1160, height: 772},
          {name: 'i_648x432', width: 648, height: 432},
          {name: 'i_420x280', width: 420, height: 280},
          {name: 'thumbnail', width: 420, height: 280},
          {name: 'default', width: 1440, height: 951},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'i_840x560', width: 840, height: 560},
          {name: 'twitter', width: 1200, height: 675},
        ],
        offer: [
          {name: 'i_1080x1920', width: 1080, height: 1920},
          {name: 'i_1440x951', width: 1440, height: 951},
          {name: 'i_1440x640', width: 1440, height: 640},
          {name: 'i_1160x772', width: 1160, height: 772},
          {name: 'i_648x432', width: 648, height: 432},
          {name: 'i_840x560', width: 840, height: 560},
          {name: 'i_420x280', width: 420, height: 280},
          {name: 'thumbnail', width: 420, height: 280},
          {name: 'default', width: 1440, height: 951},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
      },
    },
  };

  public getConfig(name: string): any {
    if (!name || (name && !this.config[name])) {
      return null;
    }

    return this.config[name];
  }
}
