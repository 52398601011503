import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';
import { Media } from '../../../../Library/media/models/media.model';

@Component({
  selector: 'app-content-module-a041',
  templateUrl: './content-module-a041.component.html',
  styleUrls: ['./content-module-a041.component.scss']
})
export class ContentModuleA041Component implements OnInit {

  @Input() contentModule: ContentModule;
  @Input() even: boolean;
  url: any;
  image: Media;

  constructor() {
  }

  ngOnInit(): void {
    if (this.contentModule.image) {
      this.image = this.contentModule.image;
    } else if (this.contentModule.slotables[0].image) {
      this.image = this.contentModule.slotables[0].image;
    }
    this.url = {
      url : this.contentModule.slotables[0]?.url,
      url_text: this.contentModule.slotables[0]?.url_text
    };
  }

}
