import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_ID, Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy, Scroll } from '@angular/router';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram, faLinkedinIn, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
} from '@fortawesome/pro-regular-svg-icons';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { PagesModule } from './@modules/page/pages.module';
import { FooterComponent } from './@shared/components/footer/footer.component';
import { HeaderComponent } from './@shared/components/header/header.component';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { NotFoundComponent } from './@shared/layouts/not-found-layout/not-found.component';
import { SharedModule } from './@shared/shared.module';
import { AppRoutingModule, HomepageResolver, PageResolver } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseComponent } from './Library/compontents/base.component';
import { CustomReuseStrategy } from './Library/custom-reuse-strategy';
import { LocaleInterceptor } from './Library/interceptors/locale.interceptor';
import { UUIDInterceptor } from './Library/interceptors/uuid.interceptor';

import {
  faAngleRight,
  faBabyCarriage,
  faCalendarDays,
  faCar,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faEnvelope,
  faFilter,
  faLocationDot,
  faMinus,
  faOven,
  faPaw,
  faPhone,
  faPlus,
  faSnowflake,
  faTv,
  faWater,
  faWaterLadder,
  faXmark
} from '@fortawesome/pro-light-svg-icons';

import {
  faCheck,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp
} from '@fortawesome/pro-solid-svg-icons';


import { ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AppLayoutComponent,
    NotFoundComponent,
    BaseComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'oblun-website'}),
    TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    SharedModule,
    PagesModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    ReactiveFormsModule,
    SwiperModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_ID,
      useValue: 'oblun',
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UUIDInterceptor,
      multi: true,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({enabled: environment.production || environment.staging}),
    },
    PageResolver,
    HomepageResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  urlBeforeRedirects: string;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) private platformId,
    router: Router, viewportScroller: ViewportScroller,
    library: FaIconLibrary,
  ) {

    router.events.pipe(
      filter(e => e instanceof Scroll),
    ).subscribe((e: Scroll) => {
      if (e.position) {
        // backward navigation
        setTimeout(() => {
          viewportScroller.scrollToPosition(e.position);
        }, 1000);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          viewportScroller.scrollToPosition(e.position);
        }, 1000);
      } else {
        // forward navigation

        const urlAfterRedirectsIndex = e.routerEvent.urlAfterRedirects.indexOf('?');
        let urlAfterRedirects = e.routerEvent.urlAfterRedirects;
        if (urlAfterRedirectsIndex > -1) {
          urlAfterRedirects = urlAfterRedirects.substr(0, urlAfterRedirectsIndex);
        }
        if (this.urlBeforeRedirects) {
          const urlIndex = this.urlBeforeRedirects.indexOf('?');
          let url = this.urlBeforeRedirects;
          if (urlIndex > -1) {
            url = url.substr(0, urlIndex);
          }
          if (urlAfterRedirects !== url) {
            viewportScroller.scrollToPosition([0, 0]);
          }
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }

      }
      this.urlBeforeRedirects = e.routerEvent.urlAfterRedirects;
    });

    let token = null;
    if (isPlatformBrowser(this.platformId)) {
      token = localStorage.getItem('token');
    }

    library.addIcons(faTwitter);
    library.addIcons(faInstagram);
    library.addIcons(faLinkedinIn);
    library.addIcons(faFacebook);
    library.addIcons(faYoutube);
    library.addIcons(faWhatsapp);
    library.addIcons(faBars);
    library.addIcons(faChevronDown);
    library.addIcons(faChevronUp);
    library.addIcons(faXmark);
    library.addIcons(fasChevronUp);
    library.addIcons(fasChevronDown);
    library.addIcons(farChevronUp);
    library.addIcons(farChevronDown);
    library.addIcons(faLocationDot);
    library.addIcons(faEnvelope);
    library.addIcons(faPhone);
    library.addIcons(faAngleRight);
    library.addIcons(faCalendarDays);
    library.addIcons(faBabyCarriage);
    library.addIcons(faWater);
    library.addIcons(faSnowflake);
    library.addIcons(faChild);
    library.addIcons(faPaw);
    library.addIcons(faChevronLeft);
    library.addIcons(faChevronRight);
    library.addIcons(fasChevronLeft);
    library.addIcons(fasChevronRight);
    library.addIcons(faCheck);
    library.addIcons(faMinus);
    library.addIcons(faPlus);
    library.addIcons(faFilter);
    library.addIcons(faCar);
    library.addIcons(faTv);
    library.addIcons(faWaterLadder);
    library.addIcons(faOven);

  }
}
