import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnquiryService } from '../../../enquiry/services/enquiry.service';
import { Enquiry } from '../../../enquiry/models/enquiry.model';
import { isPlatformBrowser } from '@angular/common';
import { ApiError } from '../../../../Library/api-data/api-error.model';
import { finalize, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecaptchaService } from '../../../../Library/services/recaptcha.service';

@Component({
  selector: 'app-slot-d300',
  templateUrl: './slot-d300.component.html',
  styleUrls: ['./slot-d300.component.scss']
})
export class SlotD300Component implements OnInit {

  contactForm: FormGroup;
  displaySuccessMessage: boolean = false;
  displayErrorMessage: boolean = false;
  loading: boolean = false;

  constructor(
    private recaptchaService: RecaptchaService,
    private enquiryService: EnquiryService,
    @Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit(): void {
    this.initContactForm();
  }

  initContactForm(): void {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      phone_number: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required),
      condition: new FormControl('', Validators.requiredTrue)
    });
  }

  onSubmit(): void {

    if (this.contactForm.invalid) {
      return;
    }
    this.loading = true;
    const data = {
      full_name: `${this.name.value} ${this.last_name.value}`,
      email: this.email.value,
      content: JSON.stringify({
        message: this.message.value,
        phone_number: this.phone_number.value
      })
    };


    this.recaptchaService.verify('feedback').pipe(
      take(1),
      switchMap((result: boolean) => {
          if (result) {
            return this.enquiryService.save(data).pipe(
              take(1)
            );
          }
          return of(null);
        }
      ),
      finalize(() => {
        this.loading = false;
      }))
      .subscribe((enquiry: Enquiry) => {
          this.displaySuccessMessage = true;
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              this.displaySuccessMessage = false;
            }, 4000);
          }
        },
        (apiError: ApiError) => {
          this.displayErrorMessage = true;
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              this.displayErrorMessage = false;
            }, 4000);
          }
        }
      );
  }

  get name(): AbstractControl {
    return this.contactForm.get('name');
  }

  get last_name(): AbstractControl {
    return this.contactForm.get('last_name');
  }

  get email(): AbstractControl {
    return this.contactForm.get('email');
  }

  get phone_number(): AbstractControl {
    return this.contactForm.get('phone_number');
  }

  get message(): AbstractControl {
    return this.contactForm.get('message');
  }


}
