<div class="w-full h-full flex flex-col md:flex-row">

  <div class="w-full md:w-14/24 h-full aspect-3/2 md:order-last">
    <img *ngIf="slotable.image"
         [src]="slotable.image.i_1440x951"
         appImgFallback="assets/img/default/oblun-default-image_1440x951.png"
         class="object-cover object-center md:min-h-[432px]" [alt]="slotable.title">
    <img *ngIf="!slotable.image"
         src="assets/img/default/oblun-default-image_1440x951.png"
         class="object-cover object-center md:min-h-[432px]" [alt]="slotable.title">
  </div>

  <div
    class="w-full flex flex-col md:w-10/24 h-full px-4 sm:px-10 pb-6 pt-5 md:py-0 md:px-0 md:ml-10 md:mr-10 h-full
           md:min-h-[432px] pb-6 md:pb-10 md:order-first">
    <h3 *ngIf="slotable.title"
        class="mt-4 md:mt-6 leading-130 font-400 text-32 md:text-56 font-serif ">{{ slotable.title }}</h3>
    <div *ngIf="slotable.description"
         class="font-sans-serif font-300 text-14 md:text-16 leading-175 mt-2 md:mt-3"
         [innerHTML]="slotable.description"></div>
    <div class="w-full md:w-auto mt-10 grow flex items-end justify-center md:justify-start">
      <a *ngIf="slotable.url"
         class="hover:cursor-pointer ui-btn h-10 flex justify-center items-center w-full md:w-auto md:min-w-56"
         [appFlLink]="slotable.url">
        <span *ngIf="!slotable.url_text" i18n="@@find_out_more">Saznaj više</span>
        <span *ngIf="slotable.url_text">{{ slotable.url_text }}</span>
      </a>
    </div>
  </div>


</div>
