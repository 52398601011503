import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SeoItem } from './seo-item.model';

@Injectable({
  providedIn: 'root'
})
export class SeoOgService {

  constructor(
    private metaService: Meta
  ) {
  }

  reset(): void {
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:type"');
    // this.metaService.removeTag('property="og:image"');
    // this.metaService.removeTag('property="og:image:alt"');
  }

  refresh(seoItem: SeoItem): void {

    const title = seoItem.seo_fb_title ? seoItem.seo_fb_title : seoItem.getTitle();
    const description = seoItem.seo_fb_description ? seoItem.seo_fb_description : seoItem.getDescription();
    const type = seoItem.seo_fb_type ? seoItem.seo_fb_type : 'website';

    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:type', content: type });

    if (seoItem.seo_fb_image_url) {
      this.metaService.updateTag({ property: 'og:image', content: seoItem.seo_fb_image_url });
      this.metaService.updateTag({ property: 'og:image:alt', content: seoItem.seo_fb_title });
    }
  }
}
