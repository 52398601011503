<section class="w-full py-4"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">

  <div class="container text-brown-900">

    <header *ngIf="!contentModule.name_hidden" class="w-full flex justify-center items-center font-serif">
      <h2 *ngIf="contentModule.name"
          class="text-center font-400 text-36 sm:text-56 leading-130 mt-5 sm:mt-8 mb-3 sm:mb-4 line-clamp-2">{{ contentModule.name }}</h2>
    </header>


    <div class="w-full flex justify-center items-center">
      <div class="w-32 sm:w-72 h-px bg-gray-400"></div>
    </div>

    <div class="flex flex-col items-center justify-center">
      <div *ngFor="let slotable of contentModule.slotables"
           class="max-w-80 sm:max-w-[670px]">
        <app-slot-a20 [slotable]="slotable"></app-slot-a20>
      </div>
    </div>

  </div>
</section>
