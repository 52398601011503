import { Component, Input, OnInit } from '@angular/core';
import { ContentModule } from '../../models/content-module.model';

@Component({
  templateUrl: './content-module-a020.component.html'
})
export class ContentModuleA020Component implements OnInit {

  @Input() contentModule: ContentModule;
  @Input() even: boolean;

  ngOnInit(): void {
  }
}
