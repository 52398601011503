<div class="w-full flex flex-col items-start text-white sm:ml-12">

  <h3 *ngIf="slotable.title"
      class="font-serif font-400 text-48 md:text-68 mt-6 sm:mt-12 leading-130 line-clamp-2">{{ slotable.title }}</h3>
  <div *ngIf="slotable.description"
       class="w-full font-sans-serif font-300 text-14 sm:text-16 mt-4 leading-175"
       [innerHTML]="slotable.description"></div>

  <a *ngIf="slotable.url"
     [appFlLink]="slotable.url"
     class="hover:cursor-pointer hover:bg-white hover:bg-opacity-40 hover:text-white mt-12 border w-full sm:w-56 flex justify-center items-center h-12 font-400 text-14 leading-170">
    <span *ngIf="!slotable.url_text" i18n="@@find_out_more">Saznaj više</span>
    <span *ngIf="slotable.url_text">{{ slotable.url_text }}</span>
  </a>
</div>

