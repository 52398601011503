import {Component, Input, OnInit} from '@angular/core';
import {ContentModule} from '../../models/content-module.model';
import { Media } from '../../../../Library/media/models/media.model';

@Component({
  selector: 'app-content-module-a040',
  templateUrl: './content-module-a040.component.html',
  styleUrls: ['./content-module-a040.component.scss']
})
export class ContentModuleA040Component implements OnInit {

  @Input() contentModule: ContentModule;
  @Input() even: boolean;
  image: Media;

  constructor() { }

  ngOnInit(): void {
    if (this.contentModule){
      if (this.contentModule.image) {
        this.image = this.contentModule.image;
      } else if (this.contentModule.slotables[0] && this.contentModule.slotables[0].image) {
        this.image = this.contentModule.slotables[0].image;
      }
    }
  }

}
