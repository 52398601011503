import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateTime } from 'luxon';
import { Resource } from '../../../Library/resource/resource.model';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { SafeHtml } from '@angular/platform-browser';

export class Slot extends Resource<Request> {
  public id: number;
  public name: string;
  public title: string;
  public description?: string;
  public slug: string;
  public path: string;
  public url: string;
  public image: Media;
  public url_text?: string;
  public microthumb: string;
  public locale: string;
  public publishedAtDate: Date;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SlotAdapter {

  constructor(
    private mediaAdapter: MediaAdapter,
    @Inject(LOCALE_ID) private localeCode: string
  ) {
  }

  adapt(data: any, slot_type: string): Slot {

    const item = data.data;

    switch (this.localeCode) {
      case 'en':
        item.locale = 'en';
        break;
      case 'sr-Latn-ME':
        item.locale = 'me';
        break;
    }

    // switch (slot_type) {
    //   case 'article':
    //     item.url = articleRoute + '/' + item.slug;
    //     break;
    //   case 'event':
    //     item.url = eventRoute + '/' + item.slug;
    //     break;
    // }

    if (item.seo_image) {
      item.image = item.seo_image;
    } else {
      // item.image = new Media({
      //   i_378x252: `/assets/img/default/seo-default-image-${item.locale}-i_378x252.png`,
      //   i_244x136: `/assets/img/default/seo-default-image-${item.locale}-i_244x136.png`,
      //   i_396x222: `/assets/img/default/seo-default-image-${item.locale}-i_396x222.png`,
      //   i_496x278: `/assets/img/default/seo-default-image-${item.locale}-i_496x278.png`,
      //   i_674x378: `/assets/img/default/seo-default-image-${item.locale}-i_674x378.png`,
      //   i_814x457: `/assets/img/default/seo-default-image-${item.locale}-i_814x457.png`,
      //   i_1200x675: `/assets/img/default/seo-default-image-${item.locale}-i_1200x675.png`,
      //   i_1200x630: `/assets/img/default/seo-default-image-${item.locale}-i_1200x630.png`,
      //   i_1920x1080: `/assets/img/default/seo-default-image-${item.locale}-i_1920x1080.png`,
      //   i_1080x1920: `/assets/img/default/seo-default-image-${item.locale}-i_1080x1920.png`,
      //   i_452x800: `/assets/img/default/seo-default-image-${item.locale}-i_452x800.png`,
      //   caption: item.title
      // });
    }

    if (item.seo_title) {
      item.title = item.seo_title;
    }

    if (item.name && !item.title) {
      item.title = item.name;
    }

    if (item.image) {
      item.image = this.mediaAdapter.adapt({data: item.image});
    }

    if (item.published_at) {
      item.publishedAtDate = DateTime.fromSQL(item.published_at, {zone: 'UTC'}).toJSDate();
    }

    return new Slot(item);
  }

  adaptArray(items: any, slot_type: string): Slot[] {
    items = (items.data.data) ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item}, slot_type);
    });
  }

}
