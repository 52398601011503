<div class="w-full flex flex-col justify-center items-start text-brown-900">
  <h3 *ngIf="slotable.title"
      class="mt-4 md:mt-6 font-400 font-serif text-28 md:text-40 leading-130 line-clamp-2">{{ slotable.title }}</h3>
  <div
    *ngIf="slotable.description"
    class="mt-3 font-sans-serif text-14 md:text-16 leading-170 font-300"
    [innerHTML]="slotable.description">
  </div>
  <a
    *ngIf="slotable.url"
    [appFlLink]="slotable.url"
    class="w-full sm:w-auto ui-btn mt-10 md:mt-6 py-2 px-18 hover:cursor-pointer">
    <span *ngIf="!slotable.url_text" i18n="@@find_out_more">Saznaj više</span>
    <span *ngIf="slotable.url_text">{{ slotable.url_text }}</span>
  </a>
</div>
