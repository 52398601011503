import { Component, Input, OnInit } from '@angular/core';
import { Media } from 'src/app/Library/media/models/media.model';
import { ContentModule } from '../../models/content-module.model';

@Component({
  selector: 'app-content-module-a010',
  templateUrl: './content-module-a010.component.html',
  styleUrls: ['./content-module-a010.component.scss']
})
export class ContentModuleA010Component implements OnInit {

  @Input() contentModule: ContentModule;
  image: Media;
  @Input() even: boolean;

  constructor() {
  }

  ngOnInit(): void {
    if (this.contentModule.image) {
      this.image = this.contentModule.image;
    } else if (this.contentModule.slotables[0].image) {
      this.image = this.contentModule.slotables[0].image;
    }
  }

}
