import { Component, Input, OnInit } from '@angular/core';
import { Media } from '../../../../Library/media/models/media.model';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a70',
  templateUrl: './slot-a70.component.html',
  styleUrls: ['./slot-a70.component.scss']
})
export class SlotA70Component implements OnInit {

  @Input() slotable: Slot;
  @Input() moduleImage: Media;

  constructor() {
  }

  ngOnInit(): void {
  }

}
