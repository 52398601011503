import {Component, Input, OnInit} from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a20',
  templateUrl: './slot-a20.component.html',
  styleUrls: ['./slot-a20.component.scss']
})
export class SlotA20Component implements OnInit {

  @Input() slotable: Slot;

  constructor() { }

  ngOnInit(): void {
  }

}
