import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import { RouteName } from '../../../Library/routes';

export const seoRulesDefault = {
  me: new SeoItem({
    title: 'Oblun eco resort - probudi prirodu u sebi',
    description: 'Dobrodošli u Oblun eco resort, smješten u samom srcu veličanstvene prirode, gdje zajedno stvaramo neponovljiv doživljaj dinamičnog okruženja, živopisne istorije i nesvakidašnjeg kulturnog šarma naše zemlje.',
    seo_fb_title: 'Oblun eco resort - probudi prirodu u sebi',
    seo_fb_description: 'Dobrodošli u Oblun eco resort, smješten u samom srcu veličanstvene prirode, gdje zajedno stvaramo neponovljiv doživljaj dinamičnog okruženja, živopisne istorije i nesvakidašnjeg kulturnog šarma naše zemlje.',
    seo_fb_image_url: '/assets/img/seo/oblun_SEO_FB_1200x630.png',
    seo_fb_type: 'website',
    seo_tw_title: 'Oblun eco resort - probudi prirodu u sebi',
    seo_tw_description: 'Dobrodošli u Oblun eco resort, smješten u samom srcu veličanstvene prirode, gdje zajedno stvaramo neponovljiv doživljaj dinamičnog okruženja, živopisne istorije i nesvakidašnjeg kulturnog šarma naše zemlje.',
    seo_tw_image_url: '/assets/img/seo/oblun_SEO_TW_1200x675.png',
    seo_keywords: 'Oblun, Eco resort, Crna Gora',
    url: '',
  }),
  en: new SeoItem({
    title: 'Oblun eco resort - awaken nature within yourself',
    description: 'Welcome to Oblun eco resort, located in the heart of magnificent nature, where together we create a unique experience of the dynamic environment, vivid history, and extraordinary cultural charm of our country.',
    seo_title: 'Oblun eco resort - awaken nature within yourself',
    seo_fb_title: 'Oblun eco resort - awaken nature within yourself',
    seo_fb_description: 'Welcome to Oblun eco resort, located in the heart of magnificent nature, where together we create a unique experience of the dynamic environment, vivid history, and extraordinary cultural charm of our country.',
    seo_fb_image_url: '/assets/img/seo/oblun_SEO_FB_1200x630.png',
    seo_description: 'Welcome to Oblun eco resort, located in the heart of magnificent nature, where together we create a unique experience of the dynamic environment, vivid history, and extraordinary cultural charm of our country.',
    seo_tw_title: 'Oblun eco resort - awaken nature within yourself',
    seo_tw_description: 'Welcome to Oblun eco resort, located in the heart of magnificent nature, where together we create a unique experience of the dynamic environment, vivid history, and extraordinary cultural charm of our country.',
    seo_tw_image_url: '/assets/img/seo/oblun_SEO_TW_1200x675.png',
    seo_fb_type: 'website',
    seo_keywords: 'Oblun, Eco resort, Montenegro',
    route_name: RouteName.HOMEPAGE,
    url: '',
  })
};

