import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a041',
  templateUrl: './slot-a041.component.html',
  styleUrls: ['./slot-a041.component.scss']
})
export class SlotA041Component implements OnInit {

  @Input() url: any;
  @Input() slotable: Slot;
  @Input() isFirstSlot: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
