import { DOCUMENT, isPlatformBrowser, isPlatformServer } from "@angular/common";
import {
  Component,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { interval, Subject, Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import {
  LocaleService,
  RouteTranslation,
} from "../../../Library/services/locale.service";
import { GlobalService } from "../../../Library/services/global.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { MenuItem } from "../../../@modules/menu/models/menu-item.model";
import { MenuService } from "../../../@modules/menu/services/menu.service";
import { filter, take, tap } from "rxjs/operators";
import { DetailService } from "../../../@modules/detail/services/detail.service";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  faTimes = faTimes;
  routeTranslations: RouteTranslation;
  locale: string;
  ngUnsubscribe = new Subject();
  isMobileLayout: boolean = null;
  menuVisible: boolean = null;
  menuTransition: boolean = false;
  showRedirectWrapper: string = null;
  translationHomepage: SafeUrl;
  timeUntilRedirect: number = 5;
  headerMenu: MenuItem[];
  languageDropdown: boolean = false;
  chosenLanguage: string;
  disableButton: boolean = false;
  private subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private localeService: LocaleService,
    private menuService: MenuService,
    private globalService: GlobalService,
    private detailService: DetailService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public localeCode,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.locale = this.localeService.getLocale();
    this.localeService.currentRouteTranslations$.subscribe(
      (routeTranslations: RouteTranslation) => {
        this.routeTranslations = routeTranslations;
      }
    );

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
      res => {
        if (this.menuVisible) {
          this.toggleMenuDialog();
        }
      }
    );

    this.chosenLanguage = this.locale;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(this.document.body, "overflow-hidden");
      this.isMobileLayout = window.innerWidth <= 768;
      window.onresize = () => (this.isMobileLayout = window.innerWidth <= 768);
    }

    this.detailService.details$
      .pipe(
        filter((result) => result != null),
        tap((result) => {
          this.headerMenu = result.additional.menus.main.items;
        }),
        take(1)
      )
      .subscribe();

    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuVisible = false;
        this.toggleOverflowClass();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  navigateTo(link: string): void {
    this.toggleMenuDialog();
    this.router.navigate([link]);
  }

  toggleMenuDialog(): void {
    if (this.disableButton) {
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.document.getElementById("menu").classList.toggle("change");
      this.menuTransition = !this.menuTransition;
      if (this.menuVisible) {
        this.disableButton = true;
        setTimeout(() => {
          this.disableButton = false;
          this.menuVisible = false;
          this.toggleOverflowClass();
        }, 1000);
      } else {
        this.menuVisible = true;
        this.toggleOverflowClass();
      }
    }
  }

  toggleLangDialog(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.toggleOverflowClass();
    }
  }

  toggleOverflowClass(): void {
    if (this.menuVisible) {
      this.renderer.addClass(this.document.body, "overflow-hidden");
    } else {
      this.renderer.removeClass(this.document.body, "overflow-hidden");
    }
  }

  redirectToHome(locale: string): void {
    if (locale === "me") {
      locale = "";
    }
    this.translationHomepage = this.sanitizer.bypassSecurityTrustUrl(
      environment.websiteUrl + "/" + locale
    );

    if (isPlatformServer(this.platformId)) {
      return;
    }

    location.href = environment.websiteUrl + "/" + locale;
  }

  openDropdown(): void {
    this.languageDropdown = true;
  }

  choseLanguage(locale: string): void {
    this.chosenLanguage = locale;
    this.languageDropdown = false;
    this.menuVisible = false;
    this.redirectToHome(locale);
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ): void {
    if (event.keyCode === 27) {
      this.menuVisible = false;
    }
  }
}
