import { Component, Input, OnInit } from '@angular/core';
import Helpers from '../../../../Library/helpers';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a60',
  templateUrl: './slot-a60.component.html',
  styleUrls: ['./slot-a60.component.scss']
})
export class SlotA60Component implements OnInit {

  @Input() slotable: Slot;

  constructor() {
  }

  ngOnInit(): void {
    this.slotable.title = Helpers.excerpt(this.slotable.title, 55);
  }

}
