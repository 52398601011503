import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-d100',
  templateUrl: './slot-d100.component.html',
  styleUrls: ['./slot-d100.component.scss']
})
export class SlotD100Component implements OnInit {

  @Input() slotable: Slot;

  constructor() { }

  ngOnInit(): void {
  }

}
