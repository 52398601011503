import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerResponseService } from 'src/app/Library/server-response.service';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {

  faArrowRight = faArrowRight;

  constructor(
    private srs: ServerResponseService,
    private router: Router,
  ) {
    srs.setNotFound();
  }

  ngOnInit(): void {
  }

}
