<section class="w-full pt-6 pb-8 sm:pt-12 sm:pb-20"
         [ngClass]="{'bg-white': contentModule.is_highlighted,
         'bg-beige-300' : even && !contentModule.is_highlighted,
         'bg-beige-500' : !even && !contentModule.is_highlighted}">
  <div class="container text-brown-900 flex flex-col items-start">
    <div *ngFor="let slotable of contentModule.slotables" class="w-full">
      <app-slot-a80 [slotable]="slotable" [image]="contentModule.image"></app-slot-a80>
    </div>
  </div>
</section>
